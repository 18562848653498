import { DIMENSION_TITLES, MEASURE_TITLES } from './analytics'

export const DATA_HEALTH_DIMENSIONS = [
  DIMENSION_TITLES.EMPLOYEE_ARRIVAL_DATE,
  DIMENSION_TITLES.EMPLOYEE_GENDER,
  DIMENSION_TITLES.EMPLOYEE_SENIORITY,
  DIMENSION_TITLES.EMPLOYEE_BIRTH_DATE,
  DIMENSION_TITLES.EMPLOYEE_NATIONALITY,
  DIMENSION_TITLES.EMPLOYEE_SALARY_BASE_EUR,
  DIMENSION_TITLES.EMPLOYEE_CAREER_LEVEL,
  DIMENSION_TITLES.EMPLOYEE_EMPLOYMENT_TYPE,
  DIMENSION_TITLES.EMPLOYEE_JOB_TITLE,
  DIMENSION_TITLES.EMPLOYEE_DEPARTMENT,
  DIMENSION_TITLES.EMPLOYEE_GROUP_1,
  DIMENSION_TITLES.EMPLOYEE_LOCATION_OFFICE
] as const

export const RELATED_MEASURES_BY_DATA_HEALTH_DIMENSIONS: {
  [key in (typeof DATA_HEALTH_DIMENSIONS)[number]]: MEASURE_TITLES[]
} = {
  [DIMENSION_TITLES.EMPLOYEE_ARRIVAL_DATE]: [
    MEASURE_TITLES.EMPLOYEE_COUNT_ARRIVAL,
    MEASURE_TITLES.EMPLOYEE_GROWTH_RATE,
    MEASURE_TITLES.EMPLOYEE_TURNOVER
  ],
  [DIMENSION_TITLES.EMPLOYEE_GENDER]: [
    MEASURE_TITLES.EMPLOYEE_COUNT_END_F,
    MEASURE_TITLES.EMPLOYEE_COUNT_END_M,
    MEASURE_TITLES.EMPLOYEE_GENDER_BASE_SALARY_GAP_RATE
  ],
  [DIMENSION_TITLES.EMPLOYEE_SENIORITY]: [
    MEASURE_TITLES.EMPLOYEE_AVERAGE_TENURE,
    MEASURE_TITLES.EMPLOYEE_AVERAGE_TIME_STAY
  ],
  [DIMENSION_TITLES.EMPLOYEE_BIRTH_DATE]: [MEASURE_TITLES.EMPLOYEE_AVERAGE_AGE],
  [DIMENSION_TITLES.EMPLOYEE_NATIONALITY]: [
    MEASURE_TITLES.EMPLOYEE_COUNT_END_NATIONALITY
  ],
  [DIMENSION_TITLES.EMPLOYEE_SALARY_BASE_EUR]: [
    MEASURE_TITLES.EMPLOYEE_MEDIAN_SALARY_TOTAL_EUR,
    MEASURE_TITLES.EMPLOYEE_AVERAGE_SALARY_TOTAL_EUR,
    MEASURE_TITLES.EMPLOYEE_SUM_SALARY_BASE_EUR,
    MEASURE_TITLES.EMPLOYEE_SUM_SALARY_TOTAL_EUR,
    MEASURE_TITLES.EMPLOYEE_COUNT_SALARY_RAISE,
    MEASURE_TITLES.EMPLOYEE_RATIO_SALARY_RAISE,
    MEASURE_TITLES.EMPLOYEE_AVERAGE_TIME_TO_SALARY_BASE_RAISE,
    MEASURE_TITLES.EMPLOYEE_AVERAGE_SALARY_BASE_RAISE_RATE,
    MEASURE_TITLES.EMPLOYEE_SUM_WITH_STUCK_SALARY
  ],
  [DIMENSION_TITLES.EMPLOYEE_CAREER_LEVEL]: [
    MEASURE_TITLES.EMPLOYEE_AVERAGE_TIME_TO_PROMOTE,
    MEASURE_TITLES.EMPLOYEE_AVERAGE_TIME_SINCE_PROMOTE,
    MEASURE_TITLES.EMPLOYEE_AVERAGE_TIME_IN_ROLE,
    MEASURE_TITLES.EMPLOYEE_VERTICAL_MOBILITY_RATE,
    MEASURE_TITLES.EMPLOYEE_COUNT_END_CAREER_LEVEL
  ],
  [DIMENSION_TITLES.EMPLOYEE_EMPLOYMENT_TYPE]: [],
  [DIMENSION_TITLES.EMPLOYEE_JOB_TITLE]: [],
  [DIMENSION_TITLES.EMPLOYEE_DEPARTMENT]: [],
  [DIMENSION_TITLES.EMPLOYEE_GROUP_1]: [],
  [DIMENSION_TITLES.EMPLOYEE_LOCATION_OFFICE]: []
}
